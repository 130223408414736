<template>
  <v-container>
    <!-- page header -->
    <v-row>
      <v-col cols="12" class="d-flex">
        <h1 class="headline font-weight-medium">Create an AV Sheet</h1>
        <v-spacer />
        <v-btn text color="primary" to="/av-sheets" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Back to AV Sheets</v-btn
        >
      </v-col>
    </v-row>
    <!-- page header -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary px-5 pt-8">
            <!-- request info -->
            <v-row no-gutters>
              <v-col cols="12" class="mb-4">
                <h3>Request Info</h3>
              </v-col>
              <!-- eventName -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Event Name</v-list-item-title>
                    <v-list-item-subtitle>{{
                      eventReport.eventName
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- eventName -->
              <!-- eventDate -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Event Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      formatDate(eventReport.eventDate)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- eventDate -->
              <!-- travelByDate -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Travel by Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      formatDate(eventReport.travelByDate)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- travelByDate -->
              <!-- lastName -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Last Name</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userAccount.lastName
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- lastName -->
              <!-- firstName -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>First Name</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userAccount.firstName
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- firstName -->
              <!-- email -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Email</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userAccount.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- email -->
              <!-- secondaryEmail -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Secondary Email</v-list-item-title>
                    <v-list-item-subtitle>{{
                      userAccount.secondaryEmail
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- secondaryEmail -->
              <!-- packageCode -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Package Code</v-list-item-title>
                    <v-list-item-subtitle>{{
                      specificWinner.packageCode
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- packageCode -->
              <!-- checkInDate -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Check In Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      formatDate(avSheet.checkInDate)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- checkInDate -->
              <!-- alternativeCheckInDate1 -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Alternative Check in Date 1</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      formatDate(avSheet.alternativeCheckInDate1)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- alternativeCheckInDate1 -->
              <!-- alternativeCheckInDate2 -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Alternative Check in Date 2</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      formatDate(avSheet.alternativeCheckInDate2)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- alternativeCheckInDate2 -->
              <!-- destination -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Destination</v-list-item-title>
                    <v-list-item-subtitle>{{
                      specificWinner.destination
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- destination -->
              <!-- numberOfAdults -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Number of Adults</v-list-item-title>
                    <v-list-item-subtitle>{{
                      avSheet.numberOfAdults
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- numberOfAdults -->
              <!-- numberOfChildren -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Number of Children</v-list-item-title>
                    <v-list-item-subtitle>{{
                      avSheet.numberOfChildren
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- numberOfChildren -->
              <!-- travelPartyInfo -->
              <v-col cols="12" md="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Travel Party Information</v-list-item-title
                    >
                    <p class="body-2">{{ avSheet.travelPartyInfo }}</p>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!-- travelPartyInfo -->
            </v-row>
            <!-- request info -->
            <!-- form -->
            <v-row class="mt-0">
              <v-col cols="12">
                <v-form ref="avSheetForm" @submit.prevent="onSubmit">
                  <v-row
                    v-for="(item, index) in items"
                    :key="index"
                    class="grey lighten-3 pa-3 pt-5 my-6"
                    style="border-radius: 4px"
                  >
                    <v-col cols="12" md="6" class="mb-4">
                      <h3>Option {{ index + 1 }}</h3>
                    </v-col>
                    <!-- delete option -->
                    <v-col cols="12" md="6" class="mb-12" v-if="index > 0">
                      <v-btn
                        icon
                        absolute
                        right
                        color="error"
                        class="mr-4"
                        @click="items.splice(index, 1)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </v-col>
                    <!-- delete option -->

                    <!-- custom input options -->
                    <v-col cols="12">
                      <v-row>
                        <!-- destination -->
                        <v-col cols="12" md="4" class="py-0 my-0">
                          <v-checkbox
                            v-model="enterManualDestination"
                            dense
                            label="I'd like to enter a destination manually"
                          ></v-checkbox>
                          <v-expand-transition>
                            <v-text-field
                              style="max-width: 340px"
                              label="Enter a destination"
                              v-if="enterManualDestination"
                              :rules="[textRules.required]"
                              :loading="loading"
                              :disabled="loading"
                              v-model="item.destination"
                            >
                            </v-text-field>
                            {{ item.destination }}
                          </v-expand-transition>
                        </v-col>
                        <!-- destination -->
                        <!-- resortName -->
                        <v-col cols="12" md="4" class="py-0 my-0">
                          <v-checkbox
                            v-model="enterManualResortName"
                            dense
                            label="I'd like to enter a resort name manually"
                          ></v-checkbox>
                          <v-expand-transition>
                            <v-text-field
                              :rules="[textRules.required]"
                              :loading="loading"
                              :disabled="loading"
                              v-model="item.resortName"
                              label="Enter a resort name"
                              v-if="enterManualResortName"
                              style="max-width: 340px"
                            >
                            </v-text-field>
                            {{ item.resortName }}
                          </v-expand-transition>
                        </v-col>
                        <!-- resortName -->
                        <!-- supplier -->
                        <v-col cols="12" md="4" class="py-0 my-0">
                          <v-checkbox
                            v-model="enterManualSupplier"
                            dense
                            label="I'd like to enter a supplier manually"
                          ></v-checkbox>
                          <v-expand-transition>
                            <v-text-field
                              style="max-width: 340px"
                              label="Enter a supplier"
                              v-if="enterManualSupplier"
                              :rules="[textRules.required]"
                              :loading="loading"
                              :disabled="loading"
                              v-model="item.supplier"
                            >
                            </v-text-field>
                            {{ item.supplier }}
                          </v-expand-transition>
                        </v-col>
                        <!-- supplier -->
                      </v-row>
                    </v-col>
                    <!-- custom input options -->

                    <!-- destination -->
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="inventoryListings"
                        dense
                        item-text="destination"
                        label="Destination"
                        :loading="loading"
                        :disabled="loading"
                        hint="Type in a Destination"
                        persistent-hint
                        return-object
                        @change="onSetDestination(index, $event)"
                        :rules="[textRules.required]"
                        v-if="!enterManualDestination"
                      ></v-autocomplete>
                      {{ item.destination }}
                    </v-col>
                    <!-- destination -->

                    <!-- resortName -->
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        no-data-text="Select a Destination First"
                        :items="item.inventoryListingItems"
                        dense
                        item-text="resortName"
                        @change="onSetResortName(index, $event)"
                        label="Resort Name"
                        v-model="item.resortName"
                        :loading="loading"
                        :disabled="loading"
                        hint="Type in a Resort Name"
                        return-object
                        persistent-hint
                        :rules="[textRules.required]"
                        v-if="!enterManualResortName"
                      ></v-autocomplete>
                      {{ item.resortName }}
                    </v-col>
                    <!-- resortName -->

                    <!-- supplier -->
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="['tripbeat', 'hsi']"
                        dense
                        label="Supplier"
                        v-model="item.supplier"
                        :loading="loading"
                        :disabled="loading"
                        hint="Type in a Supplier Name"
                        persistent-hint
                        :rules="[textRules.required]"
                        v-if="!enterManualSupplier"
                      ></v-autocomplete>
                      {{ item.supplier }}
                    </v-col>
                    <!-- supplier -->

                    <!-- unit size -->
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="[
                          '1 bedroom',
                          '2 bedroom',
                          '3 bedroom',
                          'studio',
                          'hotel',
                        ]"
                        dense
                        label="Unit Size"
                        hint="Type in a Unit Size"
                        v-model="item.unitSize"
                        :loading="loading"
                        :disabled="loading"
                        persistent-hint
                        :rules="[textRules.required]"
                      ></v-autocomplete>
                      {{ item.unitSize }}
                    </v-col>
                    <!-- unit size -->

                    <!-- max occupancy -->
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="['0', '1', '2', '3', '4', '5', '6', '7', '8']"
                        dense
                        label="Maximum Occupancy"
                        hint="Type in a Max Occupancy"
                        v-model="item.maxOccupancy"
                        :loading="loading"
                        :disabled="loading"
                        persistent-hint
                        :rules="[textRules.required]"
                      ></v-autocomplete>
                      {{ item.maxOccupancy }}
                    </v-col>
                    <!-- max occupancy -->

                    <!-- kitchen -->
                    <v-col cols="12" md="4">
                      <v-autocomplete
                        :items="[
                          'full',
                          'partial',
                          'mini',
                          'kitchenette',
                          'none',
                        ]"
                        dense
                        label="Kitchen"
                        hint="Type in a Kitchen"
                        v-model="item.kitchen"
                        :loading="loading"
                        :disabled="loading"
                        persistent-hint
                        :rules="[textRules.required]"
                      ></v-autocomplete>
                      {{ item.kitchen }}
                    </v-col>
                    <!-- kitchen -->

                    <!-- checkInDate -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        :rules="[textRules.required]"
                        label="Check In Date *"
                        @click="showCheckInDatePicker = !showCheckInDatePicker"
                        dense
                        readonly
                        :value="formatDate(item.checkInDate)"
                        append-icon="mdi-calendar-cursor"
                      ></v-text-field>
                      <v-expand-transition>
                        <div
                          class="pa-6 grey lighten-3"
                          style="border-radius: 4px"
                          v-if="showCheckInDatePicker"
                        >
                          <v-date-picker
                            full-width
                            v-model="item.checkInDate"
                            @change="showCheckInDatePicker = false"
                          ></v-date-picker>
                        </div>
                      </v-expand-transition>
                      {{ item.checkInDate }}
                    </v-col>
                    <!-- checkInDate -->

                    <!-- checkOutDate -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        :rules="[textRules.required]"
                        label="Check Out Date *"
                        @click="
                          showCheckOutDatePicker = !showCheckOutDatePicker
                        "
                        dense
                        readonly
                        :value="formatDate(item.checkOutDate)"
                        append-icon="mdi-calendar-cursor"
                      ></v-text-field>
                      <v-expand-transition>
                        <div
                          class="pa-6 grey lighten-3"
                          style="border-radius: 4px"
                          v-if="showCheckOutDatePicker"
                        >
                          <v-date-picker
                            full-width
                            v-model="item.checkOutDate"
                            @change="showCheckOutDatePicker = false"
                          ></v-date-picker>
                        </div>
                      </v-expand-transition>
                      {{ item.checkOutDate }}
                    </v-col>
                    <!-- checkOutDate -->

                    <!-- hasResortNotes -->
                    <v-col cols="12" md="4">
                      <v-select
                        label="Add Resort Notes to Traveler?"
                        dense
                        :items="[true, false]"
                        :loading="loading"
                        :disabled="loading"
                        :rules="[selectRules.boolean]"
                        v-model="item.hasResortNotes"
                        @change="
                          !item.hasResortNotes ? (item.resortNotes = '') : ''
                        "
                      ></v-select>
                      {{ item.hasResortNotes }}
                    </v-col>
                    <!-- hasTravelerNotes -->

                    <!-- resortNotes -->
                    <v-col cols="12" v-if="item.hasResortNotes">
                      <v-textarea
                        dense
                        :rules="[textRules.required]"
                        v-model="item.resortNotes"
                        :loading="loading"
                        :disabled="loading"
                        label="Resort Notes"
                      ></v-textarea>
                    </v-col>
                    <!-- resortNotes -->

                    <!-- hasSurcharges -->
                    <v-col cols="12" md="4">
                      <v-select
                        label="Add Surcharges?"
                        dense
                        :rules="[selectRules.boolean]"
                        @change="
                          item.hasSurcharges
                            ? item.surcharges.push({ type: '', amount: '' })
                            : (item.surcharges = []);
                          calcItemTotalSurcharges();
                          calcItemNetProfit();
                        "
                        :items="[true, false]"
                        :loading="loading"
                        :disabled="loading"
                        v-model="item.hasSurcharges"
                      ></v-select>
                      {{ item.hasSurcharges }}
                    </v-col>
                    <!-- hasSurcharges -->

                    <v-col
                      cols="12"
                      v-for="(surcharge, index) in item.surcharges"
                      :key="index"
                    >
                      <v-row>
                        <!-- type -->
                        <v-col cols="12" md="4">
                          <v-autocomplete
                            :items="['Holiday Fee', 'High Occupancy Unit Fee']"
                            dense
                            label="Select a Surcharge Type"
                            hint="Type in a Surcharge Type"
                            v-model="surcharge.type"
                            :loading="loading"
                            :disabled="loading"
                            persistent-hint
                            :rules="[textRules.required]"
                          ></v-autocomplete>
                        </v-col>
                        <!-- type -->
                        <!-- amount -->
                        <v-col cols="12" md="4">
                          <v-text-field
                            @keypress="limitPhoneValues($event)"
                            @input="
                              calcItemTotalSurcharges();
                              calcItemNetProfit();
                            "
                            :loading="loading"
                            :disabled="loading"
                            label="Surcharge Amount"
                            :rules="[textRules.required]"
                            dense
                            v-model="surcharge.amount"
                          ></v-text-field>
                        </v-col>
                        <!-- amount -->
                        <!-- actions -->
                        <v-col cols="12" md="4" class="pl-6">
                          <v-btn
                            icon
                            color="primary"
                            @click="
                              item.surcharges.push({ type: '', amount: '' })
                            "
                          >
                            <v-icon>mdi-plus-circle-outline</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="index > 0"
                            icon
                            color="error"
                            @click="
                              item.surcharges.splice(index, 1);
                              calcItemTotalSurcharges();
                              calcItemNetProfit();
                            "
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-col>
                        <!-- actions -->
                      </v-row>
                    </v-col>

                    <!-- sold for -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        @keypress="limitPhoneValues($event)"
                        @input="calcItemNetProfit()"
                        :loading="loading"
                        :disabled="loading"
                        label="Price Sold For"
                        :rules="[textRules.required]"
                        dense
                        v-model="item.soldFor"
                      ></v-text-field>
                    </v-col>
                    <!-- sold for -->

                    <!-- total surcharges -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        :loading="loading"
                        label="Total Surcharges"
                        dense
                        readonly
                        disabled
                        v-model="item.totalSurcharges"
                      ></v-text-field>
                    </v-col>
                    <!-- total surcharges -->

                    <!-- commissions paid -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        @keypress="limitPhoneValues($event)"
                        @input="calcItemNetProfit()"
                        :loading="loading"
                        :disabled="loading"
                        label="Commissions Paid"
                        dense
                        :rules="[textRules.required]"
                        v-model="item.commissionsPaid"
                      ></v-text-field>
                    </v-col>
                    <!-- commissions paid -->

                    <!-- sav cost -->
                    <v-col cols="12" md="4">
                      <v-text-field
                        @keypress="limitPhoneValues($event)"
                        @input="calcItemNetProfit()"
                        :loading="loading"
                        :disabled="loading"
                        label="SAV Cost"
                        dense
                        :rules="[textRules.required]"
                        v-model="item.savCost"
                      ></v-text-field>
                    </v-col>
                    <!-- sav cost -->

                    <!-- net profit-->
                    <v-col cols="12" md="4">
                      <v-text-field
                        @keypress="limitPhoneValues($event)"
                        @input="calcItemNetProfit()"
                        :loading="loading"
                        label="Net Profit"
                        dense
                        disabled
                        v-model="item.netProfit"
                      ></v-text-field>
                    </v-col>
                    <!-- net profit -->

                    <!-- divider -->
                    <v-col
                      cols="12"
                      v-if="index < items.length - 1"
                      class="pt-6 pb-0"
                      ><v-divider
                    /></v-col>
                    <!-- divider -->

                    <!-- add option -->
                    <v-col cols="12" v-if="index == items.length - 1">
                      <v-btn
                        color="primary"
                        outlined
                        class="d-block ml-auto"
                        @click="
                          items.push({
                            destination: '',
                            resortName: '',
                            supplier: '',
                            unitSize: '',
                            maxOccupancy: '',
                            kitchen: '',
                            checkInDate: '',
                            checkOutDate: '',
                            inventoryListingItems: [],
                            hasSurcharges: '',
                            surcharges: [],
                            soldFor: '',
                            totalSurcharges: '',
                            commissionsPaid: '',
                            savCost: '',
                            netProfit: '',
                            hasTravelerNotes: '',
                            travelerNotes: '',
                          })
                        "
                      >
                        Add Option
                      </v-btn>
                    </v-col>
                    <!-- add option -->
                  </v-row>
                  <v-row>
                    <!-- hasAdminNotes -->
                    <v-col cols="12" md="4">
                      <v-select
                        label="Add Admin Notes?"
                        :rules="[selectRules.boolean]"
                        dense
                        :items="[true, false]"
                        :loading="loading"
                        :disabled="loading"
                        v-model="hasAdminNotes"
                        @change="!hasAdminNotes ? (adminNotes = '') : ''"
                      ></v-select>
                      {{ hasAdminNotes }}
                    </v-col>
                    <!-- hasAdminNotes -->

                    <!-- hasTravelerNotes -->
                    <v-col cols="12" md="4">
                      <v-select
                        label="Add General Notes to Traveler?"
                        :rules="[selectRules.boolean]"
                        dense
                        :items="[true, false]"
                        :loading="loading"
                        :disabled="loading"
                        v-model="hasTravelerNotes"
                        @change="!hasTravelerNotes ? (travelerNotes = '') : ''"
                      ></v-select>
                      {{ hasTravelerNotes }}
                    </v-col>
                    <!-- hasTravelerNotes -->

                    <!-- adminNotes -->
                    <v-col cols="12" md="6" v-if="hasAdminNotes">
                      <v-textarea
                        dense
                        :rules="[textRules.required]"
                        v-model="adminNotes"
                        :loading="loading"
                        :disabled="loading"
                        label="Admin Notes"
                      ></v-textarea>
                    </v-col>
                    <!-- adminNotes -->

                    <!-- travelerNotes -->
                    <v-col cols="12" md="6" v-if="hasTravelerNotes">
                      <v-textarea
                        dense
                        :rules="[textRules.required]"
                        v-model="travelerNotes"
                        :loading="loading"
                        :disabled="loading"
                        label="General Traveler Notes"
                      ></v-textarea>
                    </v-col>
                    <!-- travelerNotes -->
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" class="mb-5">
                <v-divider />
              </v-col>
            </v-row>
            <!-- form -->
          </v-card-text>
          <v-card-actions>
            <v-btn text color="error" to="/av-sheets" exact> Cancel</v-btn>
            <v-spacer />
            <v-btn depressed color="primary" @click="onSubmit"
              >Submit AV Sheet</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- form -->
  </v-container>
</template>

<script>
import axios from "axios";
import firebase from "@/plugins/firebase";
import formatDate from "@/mixins/formatDate";
import formRules from "@/mixins/formRules";
import limitPhoneValues from "@/mixins/limitPhoneValues";
export default {
  mixins: [formatDate, formRules, limitPhoneValues],
  data: () => ({
    avSheet: "",
    userAccount: "",
    eventReport: "",
    specificWinner: "",
    inventoryListings: [],
    showCheckInDatePicker: false,
    showCheckOutDatePicker: false,
    enterManualDestination: false,
    enterManualResortName: false,
    enterManualSupplier: false,

    hasAdminNotes: "",
    adminNotes: "",
    hasTravelerNotes: "",
    travelerNotes: "",

    items: [
      {
        destination: "",
        resortName: "",
        supplier: "",
        unitSize: "",
        maxOccupancy: "",
        kitchen: "",
        checkInDate: "",
        checkOutDate: "",
        inventoryListingItems: [],
        hasSurcharges: "",
        surcharges: [],
        soldFor: "",
        totalSurcharges: "",
        commissionsPaid: "",
        savCost: "",
        netProfit: "",
        hasResortNotes: "",
        resortNotes: "",
      },
    ],
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    net() {
      let totalIncomeAmount = this.totalIncomeAmount;
      totalIncomeAmount == ""
        ? (totalIncomeAmount = 0)
        : (totalIncomeAmount = parseInt(totalIncomeAmount));

      let commissionsPaid = this.commissionsPaid;
      commissionsPaid == ""
        ? (commissionsPaid = 0)
        : (commissionsPaid = parseInt(commissionsPaid));

      let cost = this.cost;
      cost == "" ? (cost = 0) : (cost = parseInt(cost));

      return (
        totalIncomeAmount + this.surchargesTotal - (commissionsPaid + cost)
      );
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
  },
  methods: {
    async onSubmit() {
      if (this.$refs.avSheetForm.validate()) {
        await this.$store.dispatch("setLoading", true);

        // data clean up
        this.items.forEach((item) => delete item.inventoryListingItems);

        // update avSheet doc in firestore
        await firebase
          .firestore()
          .collection("avSheets")
          .doc(this.$route.params.id)
          .update({
            adminHasReplied: true,
            adminNotes: this.adminNotes,
            hasAdminNotes: this.hasAdminNotes,
            hasTravelerNotes: this.hasTravelerNotes,
            items: this.items,
            travelerNotes: this.travelerNotes,
          });

        // update userAccount doc in firestore
        let timelineItem = {
          adminActionRequired: false,
          archived: false,
          dateCreated: new Date().toISOString().substring(0, 10),
          from: "admin",
          message: "",
          readByAdmin: true,
          repliedToByAdmin: false,
          senderActionRequired: false,
          title: "We've replied to your custom travel request",
          to: this.userAccount.email,
          icon: "mdi-select-multiple-marker",
          color: "primary",
        };

        await firebase
          .firestore()
          .collection("userAccounts")
          .doc(this.avSheet.userID)
          .update({
            canBook: false,
            canBrowseInventory: false,
            newDashboardAVSheetNotification: true,
            pendingAVSheetCreation: false,
            timeline: firebase.firestore.FieldValue.arrayUnion(timelineItem),
          });

        // send new dashboard message email
        await axios.post("/api/new-dashboard-message", {
          email: this.userAccount.email,
          secondaryEmail: this.userAccount.secondaryEmail,
        });

        // success
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "AV sheet created successfully",
          timeout: 5000,
        });

        await this.$store.dispatch("setLoading", false);
        this.$router.push("/av-sheets");
        return;
      }
      // form validation error
      // snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "error",
        text: "Please check form for errors",
        timeout: 5000,
      });
      await this.$store.dispatch("setLoading", false);
      return;
    },
    onSetDestination(index, { destination, items }) {
      this.items[index].destination = destination;
      this.items[index].inventoryListingItems = items;
    },
    onSetResortName(index, { resortName, source, resortAddress }) {
      this.items[index].resortName = resortName;
      this.items[index].resortAddress = resortAddress;
      this.items[index].supplier = source;
    },
    calcItemTotalSurcharges() {
      let total = 0;
      this.items.forEach(({ surcharges }, index) => {
        surcharges.forEach(({ amount }) => {
          amount == "" ? (amount = 0) : (amount = parseInt(amount));
          total = amount + total;
          total != 0
            ? (this.items[index].totalSurcharges = total.toString())
            : (this.items[index].totalSurcharges = "");
        });
      });
    },
    calcItemNetProfit() {
      this.items.forEach(
        ({ soldFor, commissionsPaid, savCost, totalSurcharges }, index) => {
          soldFor == "" ? (soldFor = 0) : (soldFor = parseInt(soldFor));
          commissionsPaid == ""
            ? (commissionsPaid = 0)
            : (commissionsPaid = parseInt(commissionsPaid));
          savCost == "" ? (savCost = 0) : (savCost = parseInt(savCost));
          totalSurcharges == ""
            ? (totalSurcharges = 0)
            : (totalSurcharges = parseInt(totalSurcharges));

          let income = soldFor + totalSurcharges;
          let expenses = commissionsPaid + savCost;
          let netProfit = income - expenses;
          this.items[index].netProfit = netProfit;
        }
      );
    },
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      async handler(id) {
        // bind avSheet
        let avSheet = await this.$bind(
          "avSheet",
          firebase.firestore().collection("avSheets").doc(id)
        );
        // bind avSheet
        // bind userAccount
        let userAccount = await this.$bind(
          "userAccount",
          firebase.firestore().collection("userAccounts").doc(avSheet.userID)
        );
        // bind userAccount
        //bind eventReport
        await this.$bind(
          "eventReport",
          firebase
            .firestore()
            .collection("eventReports")
            .doc(userAccount.eventReportID)
        );
        //bind eventReport
        //bind inventoryListings
        let inventoryListingsRef = firebase
          .firestore()
          .collection("inventoryListings")
          .orderBy("destination");
        let inventoryListings = await inventoryListingsRef.get();
        this.inventoryListings = inventoryListings.docs.map((i) => i.data());
        //bind inventoryListings
        await this.$store.dispatch("setLoading", false);
      },
    },
    eventReport: {
      immediate: true,
      handler(report) {
        if (report) {
          this.specificWinner = report.winners.filter((i) =>
            i.packageID.includes(this.userAccount.packageID)
          )[0];
        }
      },
    },
  },
};
</script>